.form-container{
    /* background-color: #d5e9b2; */
    border:1px solid #BABABA;
    margin: 1%;
    width: 80%;
    /* align-self: center; */
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;


}

.form-left{
    width:50%;
    /* border:1px solid yellow; */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 8%;
    border-right: 4px solid rgb(56, 54, 173);
    background-color: honeydew;
    border-radius: 20px 0px 0px 20px;
}

.form-right{
    /* border:1px solid red; */
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    padding: 2%;
}

.contact-icon{
    /* border:1px solid blue; */
    font-size: 70px;
    color: green;
    padding: 0px;
    margin: 0px;
}



.form-ques{
    /* border:1px solid red; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:center;
}

.form-submit{
    border:1px solid gray;
    background-color: green!important;
    color:white;
    margin: 2%!important;
    width: 30%;
    font-size: medium;
    border-radius: 30px!important;
}

.first-name{
    width: 50%!important;
    
}

.contact-title{
font-size:xx-large;
color: black;
display: flex;
/* border: 1px solid red */

}

.contact-desc{
    font-size: medium;
    /* color: green; */
    /* font-style: italic; */
    margin: 1%;
    display: flex;    

}